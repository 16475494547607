import Vue from 'vue';

export default class EditRetirementFundGoalPlanDialogViewModel {
  private readonly view: Vue;

  readonly STEPS = [
    {
      step: 0,
      component: 'EditRetirementFundCurrentPlan',
    },
    {
      step: 1,
      component: 'EditRetirementFundYourCurrentAmount',
    },
    {
      step: 2,
      component: 'EditRetirementFundRetirementAge',
    },
    {
      step: 3,
      component: 'EditRetirementFundDefineBy',
    },
    {
      step: 4,
      component: 'EditRetirementFundNewFund',
    },
    {
      step: 5,
      component: 'EditRetirementFundSuccess',
    },
  ];

  current_step = 0;

  final_step = this.STEPS.length - 1;

  current_component = this.STEPS[this.current_step];

  public constructor(view: Vue) {
    this.view = view;
  }

  nextStep = () => {
    if (this.current_step === this.final_step) {
      this.endProcess();
      return;
    }

    if (this.current_step < this.final_step) {
      this.current_step += 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  prevStep = () => {
    if (this.current_step === 0) {
      this.endProcess();
      this.view.$emit('closeModal');
      return;
    }

    if (this.current_step > 0) {
      this.current_step -= 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  endProcess = () => {
    this.current_step = 0;
    this.current_component = this.STEPS[this.current_step];
  }
}
