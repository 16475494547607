



























import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';
import EditRetirementFundGoalPlanDialogViewModel
  from '@/vue-app/view-models/components/goals-dashboard/edit-plan-goals/edit-retirement-fund-goal-plan/edit-retirement-fund-goal-plan-dialog-view-model';

@Component({
  name: 'EditRetirementFundGoalPlanDialog',
  components: {
    EditRetirementFundCurrentPlan: () => import('./EditRetirementFundCurrentPlan.vue'),
    EditRetirementFundYourCurrentAmount: () => import('./EditRetirementFundYourCurrentAmount.vue'),
    EditRetirementFundRetirementAge: () => import('./EditRetirementFundRetirementAge.vue'),
    EditRetirementFundDefineBy: () => import('./EditRetirementFundDefineBy.vue'),
    EditRetirementFundNewFund: () => import('./EditRetirementFundNewFund.vue'),
    EditRetirementFundSuccess: () => import('./EditRetirementFundSuccess.vue'),
  },
})
export default class EditRetirementFundGoalPlanDialog extends Vue {
  @PropSync('investorGoalId', { type: String })
  synced_investor_goal_id!: string;

  @PropSync('isOpen', { type: Boolean })
  synced_is_open!: boolean;

  edit_retirement_goal_plan_view_model = Vue.observable(
    new EditRetirementFundGoalPlanDialogViewModel(this),
  );

  closeModal() {
    this.synced_is_open = false;
  }
}
